@use 'Frontend/assets/styles/mixins/mediaQueryMixins';

.iconRight {
  i {
    margin-left: var(--spacing-xs);
  }
}

.iconLeft {
  i {
    margin-right: var(--spacing-xs);
  }
}

.button {
  font-weight: var(--button-font-weight);
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 0.1rem solid transparent;
  box-shadow: none;
  text-shadow: none;
  transition: all 0.3s ease;

  &.shapeCircle {
    i {
      margin: 0;
    }
  }

  &.sizeDefault {
    height: var(--button-size);
    font-size: var(--button-font-size);
    border-radius: var(--button-border-radius);
    padding: var(--button-padding);

    &.shapeCircle {
      width: var(--button-size);
      border-radius: 50%;
    }
  }

  &.sizeSmall {
    height: var(--button-size-small);
    font-size: var(--button-font-size-small);
    border-radius: var(--button-border-radius-small);
    padding: var(--button-padding-small);

    &.shapeCircle {
      width: var(--button-size-small);
      border-radius: 50%;
    }
  }

  &.sizeLarge {
    height: var(--button-size-large);
    font-size: var(--button-font-size-large);
    border-radius: var(--button-border-radius-large);
    padding: var(--button-padding-large);

    &.shapeCircle {
      width: var(--button-size-large);
      border-radius: 50%;
    }
  }

  &.primary {
    color: var(--button-primary-color);
    border-color: var(--button-primary-border-color);
    background: var(--button-primary-background);

    @include mediaQueryMixins.cursorHover {
      &:hover {
        color: var(--button-primary-hover-color);
        border-color: var(--button-primary-hover-border-color);
        background: var(--button-primary-hover-background);
      }
    }
  }

  &.secondary {
    color: var(--button-secondary-color);
    border-color: var(--button-secondary-border-color);
    background: var(--button-secondary-background);

    @include mediaQueryMixins.cursorHover {
      &:hover {
        color: var(--button-secondary-hover-color);
        border-color: var(--button-secondary-hover-border-color);
        background: var(--button-secondary-hover-background);
      }
    }
  }

  &.primarySupport {
    color: var(--button-primary-support-color);
    border-color: var(--button-primary-support-border-color);
    background: var(--button-primary-support-background);

    @include mediaQueryMixins.cursorHover {
      &:hover {
        color: var(--button-primary-support-hover-color);
        border-color: var(--button-primary-support-hover-border-color);
        background: var(--button-primary-support-hover-background);
      }
    }
  }

  &.dark {
    color: var(--button-dark-color);
    border-color: var(--button-dark-border-color);
    background: var(--button-dark-background);

    @include mediaQueryMixins.cursorHover {
      &:hover {
        color: var(--button-dark-hover-color);
        border-color: var(--button-dark-hover-border-color);
        background: var(--button-dark-hover-background);
      }
    }
  }

  &.darkGhost {
    color: var(--button-dark-ghost-color);
    border-color: var(--button-dark-ghost-border-color);
    background: var(--button-dark-ghost-background);

    @include mediaQueryMixins.cursorHover {
      &:hover {
        color: var(--button-dark-ghost-hover-color);
        border-color: var(--button-dark-ghost-hover-border-color);
        background: var(--button-dark-ghost-hover-background);
      }
    }
  }

  &.light {
    color: var(--button-light-color);
    border-color: var(--button-light-border-color);
    background: var(--button-light-background);

    @include mediaQueryMixins.cursorHover {
      &:hover {
        color: var(--button-light-hover-color);
        border-color: var(--button-light-hover-border-color);
        background: var(--button-light-hover-background);
      }
    }
  }

  &.lightGhost {
    color: var(--button-light-ghost-color);
    border-color: var(--button-light-ghost-border-color);
    background: var(--button-light-ghost-background);

    @include mediaQueryMixins.cursorHover {
      &:hover {
        color: var(--button-light-ghost-hover-color);
        border-color: var(--button-light-ghost-hover-border-color);
        background: var(--button-light-ghost-hover-background);
      }
    }
  }

  &:disabled {
    border-color: var(--button-disabled-background);
    background: var(--button-disabled-background);
    color: var(--button-disabled-color);
    cursor: default;

    @include mediaQueryMixins.cursorHover {
      &:hover {
        border-color: var(--button-disabled-background);
        background: var(--color-grey-500);
        color: var(--button-disabled-color);
      }
    }
  }
}